import React from "react";
import "./banner.css";
import banner from "../../assets/banner/banner.svg";
import quote from "../../assets/banner/quote.svg";
import { Button, Container } from "react-bootstrap";
import arrow from "../../assets/banner/arrow.svg";

function Banner() {
  return (
    <section id="banner">
      <div className="banner-section">
        <img src={banner} alt="banner" className="banner-img" />
      </div>
      <Container>
        <div className="banner-text">
          <div className="first-line">
            <span className="one">1</span>
            <span className="quote-img">
              <img src={quote} alt="quote" className="quote-image"/>
            </span>
            <span className="scribe">SCRIBE</span>
            <span className="allyour">all your</span>
          </div>
          <div className="second-line">
          Exclusive offers on <span className="great">REGULAR</span> visits. 
            {/* Platforms with <span className="great">GREAT</span> discounts! */}
          </div>
          {/* <div className="third-line">Buy | Manage | Track | Discover</div> */}
          <div className="third-line">Subscribe | Save | Enjoy</div>
          <div className="join-morebtn">
            <a href="/#contact" className="str">
              <Button className="join-now">Join now </Button>
            </a>
          </div>
        </div>
        <div className="downarrow">
          <div className="arrow1">
            <img src={arrow} alt="arrow" className="arrow" />
          </div>
          <div className="arrow2">
            <img src={arrow} alt="arrow" className="arrow" />
          </div>
        </div>
      </Container>
    </section>
  );
}

export default Banner;
