import React from "react";
import "./business.css";
import { Container, Row, Col } from "react-bootstrap";
import star from "./../../assets/customers/star.svg";
import system from "./../../assets/business/system.svg";

const Business = () => {
  return (
    <section id="business">
      <Container>
        <div className="business-container">
          <Row>
            <Col
              lg={5}
              className=" first-container justify-content-center center"
            >
              <div className="background-business-one">
                <div className="business-details-div">
                <div className="business-heading">For Business</div>
                <div className="business-details">
                  <img src={star} alt="" />
                  {/* <span>Reduce CAC & Marketing</span> */}
                  <span>Improved Sales & CLV (Customer Lifetime Value)</span>
                </div>
                <div className="business-details">
                  <img src={star} alt="" />
                  {/* <span>Increased Sales</span> */}
                  <span>Predictable revenue & Inventory Management</span>
                </div>
                <div className="business-details">
                  <img src={star} alt="" />
                  {/* <span>Going online</span> */}
                  <span>Reduced CAC & Marketing cost</span>
                </div>
                <div className="business-details">
                  <img src={star} alt="" />
                  {/* <span>Easy Communication</span> */}
                  <span>Opportunity for upselling & cross-selling</span>
                </div>
                </div>
              </div>
            </Col>
            <Col lg={7} className="lap-container">
              <div className="circle-system">
              <img src={system} alt=""  className="system" />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default Business;
